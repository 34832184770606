import { Link } from '@remix-run/react';
import { useNavigate } from '@remix-run/react';
import { format } from 'date-fns';
import useSWRImmutable from 'swr/immutable';

import { apiService } from '../../../services/api-service';
import {
  GlobalPairingRoundStatus,
  type PairingParticipationStats,
  type PairingRound,
  PairingRoundStatus,
} from '../../../types';
import { type Action, ActionSheet } from '../../ActionSheet';
import { ErrorMessage } from '../../Game/GameCenter';
import { PauseIcon } from '../../icons/PauseIcon';
import { PlayIcon } from '../../icons/PlayIcon';
import { Loading } from '../../Loading';
import { percentage } from '../utils';
import { type UsePairingRoundsProps, useRounds } from './CRUD';

type PairingRoundColumnKeys =
  | 'createdAt'
  | 'status'
  | 'globalStatus'
  | 'startedEndedAt'
  | 'gamePack'
  | 'completedPlayers'
  | 'completedPairings'
  | 'lastPlayedAt'
  | 'organization'
  | 'channel'
  | 'participation'
  | 'actionSheet';

type PairingRoundColumns = {
  [key in PairingRoundColumnKeys]?: boolean;
};

const RoundActionSheet = (props: {
  round: PairingRound;
  onToggleStatus: (roundId: string, status: PairingRoundStatus) => void;
}): JSX.Element => {
  const actions: Action<string>[] = [];
  if (props.round.status === PairingRoundStatus.Active) {
    actions.push({
      kind: 'button',
      key: 'pause',
      icon: <PauseIcon />,
      text: 'Opt out This Channel',
      onClick: () =>
        props.onToggleStatus(props.round.id, PairingRoundStatus.Paused),
    });
  } else {
    actions.push({
      kind: 'button',
      key: 'active',
      icon: <PlayIcon />,
      text: 'Opt in This Channel',
      onClick: () =>
        props.onToggleStatus(props.round.id, PairingRoundStatus.Active),
    });
  }

  return <ActionSheet actions={actions} />;
};

const PairingRoundTableHeader = ({
  columns,
}: {
  columns: PairingRoundColumns;
}): JSX.Element => {
  return (
    <tr className='text-left h-10'>
      {columns.createdAt && <th className='pl-1.5'>Created</th>}
      {columns.channel && <th className='pl-1.5'>Channel</th>}
      {columns.organization && <th className='pl-1.5'>Organization</th>}
      {columns.globalStatus && <th>Status</th>}
      {columns.status && <th>Status</th>}
      {columns.startedEndedAt && <th>Start/End Dates</th>}
      {columns.gamePack && <th>Game Pack</th>}
      {columns.completedPairings && <th>Completed Pairs</th>}
      {columns.completedPlayers && <th>Completed Players</th>}
      {columns.participation && <th>Opt In/Out</th>}
      {columns.lastPlayedAt && <th>Last Session</th>}
      {columns.actionSheet && <th></th>}
    </tr>
  );
};

function useParticipationStats(
  roundId: string
): PairingParticipationStats | undefined {
  const { data } = useSWRImmutable(
    `/pairing-rounds/${roundId}/participation-stats`,
    async () =>
      (await apiService.pairing.getOrgRoundParticipationStats(roundId)).data
        .stats
  );
  return data;
}

const PairingRoundTableRow = (props: {
  round: PairingRound;
  columns: PairingRoundColumns;
  actionSheet: React.ReactNode;
}): JSX.Element => {
  const { round, columns, actionSheet } = props;
  const navigate = useNavigate();
  const stats = useParticipationStats(round.id);

  return (
    <tr
      className='h-8 text-sms cursor-pointer hover:bg-lp-gray-002'
      onClick={() => {
        navigate(
          `/admin/organizations/${round.orgId}/pairing-rounds/${round.id}/details`
        );
      }}
    >
      {columns.createdAt && (
        <td className='pl-1.5'>
          {format(new Date(round.createdAt), 'MM/dd/yyyy')}
        </td>
      )}
      {columns.channel && (
        <td className='pl-1.5'>
          <p className='max-w-60 truncate'>{round.channel?.name ?? 'N/A'}</p>
        </td>
      )}
      {columns.organization && (
        <td className='pl-1.5'>
          <p className='max-w-60 truncate'>{round.organization?.name}</p>
        </td>
      )}
      {columns.globalStatus && (
        <td>
          <span
            className={`${
              round.globalRound?.status !== GlobalPairingRoundStatus.InProgress
                ? 'text-icon-gray'
                : ''
            }`}
          >
            {round.globalRound?.status || 'N/A'}
          </span>
        </td>
      )}
      {columns.status && (
        <td>
          {round.status === PairingRoundStatus.Active ? 'Active' : 'Opted Out'}
        </td>
      )}
      {columns.startedEndedAt && (
        <td>
          {format(new Date(round.startedAt), 'MM/dd/yyyy')} -{' '}
          {format(new Date(round.endedAt), 'MM/dd/yyyy')}
        </td>
      )}
      {columns.gamePack && (
        <td>
          {round.mainGamePack ? (
            round.mainGamePack.name
          ) : (
            <span className='text-red-002'>ERROR</span>
          )}
        </td>
      )}
      {columns.completedPairings && (
        <td>{percentage(round.completedPairingsCount, round.pairingsCount)}</td>
      )}
      {columns.completedPlayers && (
        <td>{percentage(round.completedPlayersCount, round.playersCount)}</td>
      )}
      {columns.participation && (
        <td>
          <Link
            to={`/admin/organizations/${round.orgId}/pairing-rounds/${round.id}/participation-detail`}
            className='underline'
            onClick={(e) => e.stopPropagation()}
          >
            {stats?.optedIn ?? 0}/{stats?.optedOut ?? 0}
          </Link>
        </td>
      )}
      {columns.lastPlayedAt && (
        <td>
          {round.lastSessionPlayedAt
            ? format(new Date(round.lastSessionPlayedAt), 'MM/dd/yyyy')
            : 'N/A'}
        </td>
      )}
      {columns.actionSheet && (
        <td className='pr-1.5'>
          <div className='flex flex-row-reverse items-center'>
            {actionSheet}
          </div>
        </td>
      )}
    </tr>
  );
};

export const PairingRoundTable = (props: {
  params: UsePairingRoundsProps;
  columns: PairingRoundColumns;
}): JSX.Element => {
  const { params, columns } = props;

  const { rounds, isLoading, error, reload, update } = useRounds(params);

  const handleToggleStatus = async (
    roundId: string,
    status: PairingRoundStatus
  ) => {
    const resp = await apiService.pairing.updateRound(roundId, { status });
    if (!resp) return;
    update(resp.data.round);
  };

  return (
    <div className='w-full h-full text-white'>
      <table className='w-full'>
        <thead>
          <PairingRoundTableHeader columns={columns} />
        </thead>
        <tbody>
          {rounds?.map((round) => (
            <PairingRoundTableRow
              key={round.id}
              round={round}
              columns={columns}
              actionSheet={
                <RoundActionSheet
                  round={round}
                  onToggleStatus={handleToggleStatus}
                />
              }
            />
          ))}
        </tbody>
      </table>

      {isLoading && <Loading />}
      {error && (
        <div className='w-full flex items-center justify-center'>
          <ErrorMessage text='Something went wrong' handleRetry={reload} />
        </div>
      )}
      {rounds?.length === 0 && (
        <div className='w-full mt-50 flex items-center justify-center text-secondary'>
          No Pairs Rounds.
        </div>
      )}
    </div>
  );
};

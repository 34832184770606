import { useCallback, useMemo, useRef, useState } from 'react';
import { Waypoint } from 'react-waypoint';

import { useAsyncCall } from '../../hooks/useAsyncCall';
import { useListLoader } from '../../hooks/useListLoader';
import {
  apiService,
  type UpdateOrganizersPairingStatusRequest,
} from '../../services/api-service';
import { type Organization, type Organizer } from '../../types';
import { err2s } from '../../utils/common';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { ErrorMessage } from '../Game/GameCenter';
import { Loading } from '../Loading';
import { getOrganizerDisplayName } from '../Organization';

const ParticipantRow = (props: {
  organizer: Organizer;
  onStatusChange: (pairingDisabled: boolean) => void;
}): JSX.Element => {
  const { organizer, onStatusChange } = props;
  return (
    <tr>
      <td className='py-0.75'>
        <div className='flex items-center'>
          <input
            type='checkbox'
            className='w-4 h-4'
            checked={!organizer.pairingDisabled}
            onChange={(value) => {
              onStatusChange(!value.target.checked);
            }}
          />
        </div>
      </td>
      <td className='py-0.75'>{getOrganizerDisplayName(organizer)}</td>
      <td className='py-0.75'>{organizer.email}</td>
    </tr>
  );
};

export const PairingParticipantsEditModal = ({
  org,
  onCancel,
  onSave,
}: {
  org: Organization;
  onCancel: () => void;
  onSave: (pairingParticipantsCount: number) => void;
}): JSX.Element => {
  const paginator = useMemo(
    () =>
      apiService.organization.getOrganizers(org.id, {
        type: 'all',
      }),
    [org.id]
  );
  const {
    items,
    dao,
    state: listState,
    error: listError,
    handleLoadMore,
    handleRetry,
  } = useListLoader(paginator, (a, b) => a.uid === b.uid);
  const showEmptyMsg =
    listState.isDone &&
    !listError &&
    items.length === 0 &&
    !paginator.hasMore();
  const canLoadMore = listState.isDone && !listError && paginator.hasMore();

  const sortedOrganizers = useMemo(() => {
    return items.sort((a, b) => {
      if (!a.activated && !b.activated) return a.email.localeCompare(b.email);
      if (a.activated && b.activated)
        return (a.firstName + a.lastName).localeCompare(
          b.firstName + b.lastName
        );
      return Number(b.activated) - Number(a.activated);
    });
  }, [items]);

  const {
    state: { transformed: updateState },
    error: updateError,
    call,
  } = useAsyncCall(
    useCallback((orgId: string, req: UpdateOrganizersPairingStatusRequest) => {
      return apiService.organization.toggleOrganizersPairingStatus(orgId, req);
    }, [])
  );

  const [
    pairingDisabledParticipantsCount,
    setPairingDisabledParticipantsCount,
  ] = useState(org.pairingDisabledParticipantsCount);
  const updatedMapRef = useRef<Record<string, boolean>>({});

  const handlePairingStatusChange = (
    organizer: Organizer,
    pairingDisabled: boolean
  ) => {
    dao.updateItem({ uid: organizer.uid, pairingDisabled });
    setPairingDisabledParticipantsCount((prev) =>
      pairingDisabled ? prev + 1 : prev - 1
    );

    updatedMapRef.current[organizer.uid] = pairingDisabled;
  };

  const handleSave = () => {
    call(org.id, {
      enabledUids: Object.keys(updatedMapRef.current).filter(
        (u) => !updatedMapRef.current[u]
      ),
      disabledUids: Object.keys(updatedMapRef.current).filter(
        (u) => updatedMapRef.current[u]
      ),
    });

    onSave(pairingDisabledParticipantsCount);
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <ModalWrapper
      onClose={onCancel}
      borderStyle='white'
      containerClassName='w-160'
    >
      <div className='w-full max-h-[75vh] py-10 px-5 flex flex-col items-center gap-8'>
        <div className='flex-shrink-0 flex flex-col items-center gap-1'>
          <h1 className='text-2xl font-medium'>Edit Participants</h1>
          <h2 className='font-bold text-base'>
            ({org.organizersCount - pairingDisabledParticipantsCount} out of{' '}
            {org.organizersCount} members active)
          </h2>
          <p className='text-xs font-light'>
            Only active members will be included when generating a Random
            Pairing Round.
          </p>
        </div>

        <div className='flex-shrink w-full border border-secondary p-4 rounded-xl overflow-y-auto'>
          <table className='w-full table-fixed'>
            <colgroup>
              <col className='w-2/12' />
              <col className='w-5/12' />
              <col className='w-5-12' />
            </colgroup>
            <thead>
              <tr>
                <td>Active</td>
                <td>Name</td>
                <td>Email Address</td>
              </tr>
            </thead>
            <tbody className='font-normal text-sms'>
              {sortedOrganizers.map((item) => (
                <ParticipantRow
                  key={item.uid}
                  organizer={item}
                  onStatusChange={(disabled) =>
                    handlePairingStatusChange(item, disabled)
                  }
                />
              ))}
            </tbody>
          </table>
          <div className='w-full'>
            {listState.isRunning && <Loading />}
            {listError && (
              <div className='w-full flex items-center justify-center'>
                <ErrorMessage
                  text='Something went wrong'
                  handleRetry={handleRetry}
                />
              </div>
            )}
            {showEmptyMsg && (
              <div className='w-full flex items-center justify-center text-secondary'>
                No Members.
              </div>
            )}
            {canLoadMore && (
              <Waypoint onEnter={handleLoadMore} fireOnRapidScroll>
                <div>&nbsp;</div>
              </Waypoint>
            )}
          </div>
        </div>

        <div className='flex-shrink-0 flex flex-col gap-1'>
          {updateError && (
            <div className='w-full text-center text-3xs text-red-005'>
              Saving Failed. Err Msg: {err2s(updateError)}
            </div>
          )}
          <div className='flex justify-center items-center gap-4'>
            <button className='btn-secondary w-40 h-10' onClick={handleCancel}>
              Cancel
            </button>
            <button
              className='btn-primary w-40 h-10'
              onClick={handleSave}
              disabled={
                updateState.isRunning ||
                Object.keys(updatedMapRef.current).length === 0
              }
            >
              {updateState.isRunning ? 'Saving' : 'Save'}
            </button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
